.sider-summary {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.summary {
  padding: 40px;
}

.summary .links {
  margin-top: 15vh;
}

.summary .ant-image-mask {
  display: none;
}

.detail {
  padding: 40px;
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.detail .closing,
.contact {
  margin-top: 24px;
}

@media (max-width: 991px) {
  .container {
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  .sider-summary {
    height: unset;
  }

  .summary .links {
    margin-top: 48px;
  }

  .detail {
    padding-top: 0px;
    max-height: unset;
  }

  .detail .projects {
    margin-top: 48px;
  }
}
